import {activeAudio} from 'stores/audioStore';

export default {
    stopAllAudio(currentAudio = null) {
        // For Task(group)-introductions we are using svelte store to keep track of active audio
        activeAudio.update((audios) => {
            if (currentAudio) {
                audios.forEach((a) => {
                    if (a !== currentAudio) {
                        a.pause();
                    }
                });
                return audios.filter((a) => a === currentAudio);
            }

            audios.forEach((a) => a.pause());
            return [];
        });

        // Find all other media elements (Embedded vids, SourceTextToSpeech, etc) and pause those.
        for (const mediaPlayerElement of document.querySelectorAll('audio,video')) {
            if (!currentAudio || (mediaPlayerElement !== currentAudio)) {
                mediaPlayerElement.pause()
            }
        }
    },
};
