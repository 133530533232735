var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"sideline") : stack1), depth0))
    + " js-title-sideline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"indexLetter") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":11,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isInList.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"activityType") : depth0),"exam","generated_exam","diagnostic_exam",{"name":"isInList","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":15,"column":33}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isInList.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"activityType") : depth0),"linear","","adaptive","adaptive_student",{"name":"isInList","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":27,"column":33}}})) != null ? stack1 : "")
    + "                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"js-task-index-container "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"task-index-container") : stack1), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"hasLinkToTask") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":92},"end":{"line":7,"column":164}}})) != null ? stack1 : "")
    + "\">\n                            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"hasTextToSpeech") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":123}}})) != null ? stack1 : "")
    + "\n                            <span class=\"js-task-index "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"task-index") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"indexLetter") : depth0), depth0))
    + "</span>\n                        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"task-index-container--hoverable") : stack1), depth0))
    + " ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <div class=\"js-text-to-speech "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"text-to-speech") : stack1), depth0))
    + "\"></div> ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"exam-points-container") : stack1), depth0))
    + " js-task-exam-points\"></div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"grade-score") : stack1), depth0))
    + " js-grade-score-holder\">\n\n                            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"grade-score__score-holder") : stack1), depth0))
    + " js-grade-holder\" data-graded-by=\"\">\n                                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"grade-score__score") : stack1), depth0))
    + " js-grade-score\"></div>\n                            </div>\n\n                            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"grade-score__title") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"score",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":24,"column":71},"end":{"line":24,"column":85}}}))
    + "</div>\n\n                        </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"js-introduction work-on__task-title\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"introduction") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../util/handlebarsHelpers/isNotEqual.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"activityType") : depth0),"presentation",{"name":"isNotEqual","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(46, data, 0),"data":data,"loc":{"start":{"line":40,"column":8},"end":{"line":164,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInPresentMode") : depth0),{"name":"unless","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":166,"column":8},"end":{"line":169,"column":19}}})) != null ? stack1 : "")
    + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInPresentMode") : depth0),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":45,"column":19}}})) != null ? stack1 : "")
    + "\n            <div class=\"js-answer-explanation\"></div>\n\n            <div class=\"js-task-answer no-print "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"task-answer") : stack1), depth0))
    + "\">\n\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isNotInList.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"activityType") : depth0),"adaptive","adaptive_student",{"name":"isNotInList","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(43, data, 0),"data":data,"loc":{"start":{"line":51,"column":16},"end":{"line":157,"column":32}}})) != null ? stack1 : "")
    + "            </div>\n\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n            <div class=\"work-on__task-feedback "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isStudent.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isStudent","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":47},"end":{"line":43,"column":113}}})) != null ? stack1 : "")
    + " js-feedback\"></div>\n\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "is-student "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"student-feedback") : stack1), depth0));
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_answers") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":20},"end":{"line":98,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isTeacher.js")).call(alias1,{"name":"isTeacher","hash":{},"fn":container.program(33, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":100,"column":20},"end":{"line":136,"column":34}}})) != null ? stack1 : "")
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasModelAnswer") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":55,"column":24},"end":{"line":96,"column":31}}})) != null ? stack1 : "")
    + "\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"no_answer") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":57,"column":28},"end":{"line":76,"column":35}}})) != null ? stack1 : "")
    + "\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                                <div class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "\">\n                                    "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias2,"cross",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":60,"column":36},"end":{"line":60,"column":55}}})) != null ? stack1 : "")
    + "\n                                    <span>"
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"No answer to this question",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":61,"column":42},"end":{"line":61,"column":77}}}))
    + "</span>\n                                </div>\n\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"js-show-answer "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"btn") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isStandalone") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":77},"end":{"line":65,"column":129}}})) != null ? stack1 : "")
    + "\">\n                                        "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias1,"lightbulb",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":66,"column":40},"end":{"line":66,"column":63}}})) != null ? stack1 : "")
    + "\n                                        <span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isStandalone") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":68,"column":44},"end":{"line":72,"column":51}}})) != null ? stack1 : "")
    + "                                        </span>\n                                    </div>\n\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"is-standalone") : stack1), depth0));
},"26":function(container,depth0,helpers,partials,data) {
    return "                                                "
    + container.escapeExpression(__default(require("../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Check my answer",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":69,"column":48},"end":{"line":69,"column":72}}}))
    + "\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "                                                "
    + container.escapeExpression(__default(require("../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Answer to this question",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":71,"column":48},"end":{"line":71,"column":80}}}))
    + "\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasExplanation") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":80,"column":28},"end":{"line":94,"column":35}}})) != null ? stack1 : "")
    + "\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                                <div class=\"js-show-answer "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"btn") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"isStandalone") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":73},"end":{"line":82,"column":125}}})) != null ? stack1 : "")
    + "\">\n                                    "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias2,"lightbulb",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":83,"column":36},"end":{"line":83,"column":59}}})) != null ? stack1 : "")
    + "\n                                    <span>"
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"Explanation of the answer",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":84,"column":42},"end":{"line":84,"column":76}}}))
    + "</span>\n                                </div>\n\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../../../util/handlebarsHelpers/isNotMobile.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isNotMobile","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":24},"end":{"line":117,"column":40}}})) != null ? stack1 : "")
    + "\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isPreview") : depth0),{"name":"unless","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":28},"end":{"line":116,"column":39}}})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInPresentMode") : depth0),{"name":"unless","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":32},"end":{"line":108,"column":43}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasTaskAnalytics") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":32},"end":{"line":115,"column":39}}})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"js-check-answer "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"btn") : stack1), depth0))
    + "\">\n                                    "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias2,"people",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":105,"column":36},"end":{"line":105,"column":56}}})) != null ? stack1 : "")
    + "\n                                    <span>"
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"Answers by students",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":106,"column":42},"end":{"line":106,"column":70}}}))
    + "</span>\n                                </div>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"js-open-analytics "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"btn") : stack1), depth0))
    + "\">\n                                    "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias2,"trend-up",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":112,"column":36},"end":{"line":112,"column":58}}})) != null ? stack1 : "")
    + "\n                                    <span>"
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"Task analytics",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":113,"column":42},"end":{"line":113,"column":65}}}))
    + "</span>\n                                </div>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"canAddResponses") : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":24},"end":{"line":134,"column":31}}})) != null ? stack1 : "")
    + "\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"js-response-saved-status "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "\" data-status=\"unsaved\">\n                                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"server-unsaved",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":123,"column":32},"end":{"line":123,"column":60}}})) != null ? stack1 : "")
    + "\n                                <span>"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"No answer is saved",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":124,"column":38},"end":{"line":124,"column":65}}}))
    + "</span>\n                            </div>\n                            <div class=\"js-response-saved-status "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "\" data-status=\"syncing\">\n                                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"server-syncing",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":127,"column":32},"end":{"line":127,"column":60}}})) != null ? stack1 : "")
    + "\n                                <span>"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"Answer is saved locally and syncing to server",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":128,"column":38},"end":{"line":128,"column":92}}}))
    + "</span>\n                            </div>\n                            <div class=\"js-response-saved-status "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "\" data-status=\"saved\">\n                                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"server-saved",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":131,"column":32},"end":{"line":131,"column":58}}})) != null ? stack1 : "")
    + "\n                                <span>"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"Answer is saved on server",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":132,"column":38},"end":{"line":132,"column":72}}}))
    + "</span>\n                            </div>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isStudent.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isStudent","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":140,"column":20},"end":{"line":155,"column":34}}})) != null ? stack1 : "")
    + "\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                            <div class=\"js-response-saved-status "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "\" data-status=\"unsaved\">\n                                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"server-unsaved",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":143,"column":32},"end":{"line":143,"column":60}}})) != null ? stack1 : "")
    + "\n                                <span>"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"No answer is saved",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":144,"column":38},"end":{"line":144,"column":65}}}))
    + "</span>\n                            </div>\n                            <div class=\"js-response-saved-status "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "\" data-status=\"syncing\">\n                                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"server-syncing",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":147,"column":32},"end":{"line":147,"column":60}}})) != null ? stack1 : "")
    + "\n                                <span>"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"Answer is saved locally and syncing to server",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":148,"column":38},"end":{"line":148,"column":92}}}))
    + "</span>\n                            </div>\n                            <div class=\"js-response-saved-status "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "\" data-status=\"saved\">\n                                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"server-saved",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":151,"column":32},"end":{"line":151,"column":58}}})) != null ? stack1 : "")
    + "\n                                <span>"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"Answer is saved on server",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":152,"column":38},"end":{"line":152,"column":72}}}))
    + "</span>\n                            </div>\n\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n            <div class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"send-answer-btn") : stack1), depth0))
    + " js-send-presentation-answer\"></div>\n\n";
},"48":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"js-work-on-answer\"></div>\n            <div class=\"js-task-analytics\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"center-panel\">\n    <article class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"task") : stack1), depth0))
    + "\" data-task-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"task_id") : depth0), depth0))
    + "\">\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"task-title") : stack1), depth0))
    + "\">\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isNotEqual.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"activityType") : depth0),"presentation",{"name":"isNotEqual","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":29,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"introduction") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":32,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"task-container") : stack1), depth0))
    + "\">\n            <div class=\"js-content\"></div>\n        </div>\n\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isNotAuthor.js")).call(alias3,{"name":"isNotAuthor","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":171,"column":24}}})) != null ? stack1 : "")
    + "\n    </article>\n</div>\n";
},"useData":true});