import { writable } from 'svelte/store';

export const activeAudio = writable([]);

export function addActiveAudio(audio) {
    activeAudio.update((audios) => [...audios, audio]);
}

export function removeActiveAudio(audio) {
    activeAudio.update((audios) => audios.filter(a => a !== audio));
}
