import Styles from './TaskGroup.scss';

import Template from './TaskGroup.hbs';
import Util from 'util/util';
import TaskView from 'views/components/taskGroups/tasks/Task';
import SourceView from 'views/components/taskGroups/sources/Source';
import Button from '../button/Button';
import SidebarLearningText from 'views/pages/activities/show/sidebars/learningText/LearningText';
import TheorySourcesCollection from 'collections/TheorySourcesCollection';
import TextToSpeech from 'views/components/taskGroups/TextToSpeech.svelte';
import AudioUtil from 'util/AudioUtil';

export default class TaskGroup extends BaseView {

    /**
     * initialize
     *
     * this.model (TaskGroupModel)                  Model containing information on the task group.
     *
     * @param {BaseView} work_on                    Required parent view.
     * @param {boolean|undefined} isPreview         If true, parent activity is in preview mode, meaning it's
     *                                              read-only and answers cannot be entered or modified.
     * @param {boolean|undefined} hasLinkToTask     If true, clicking the task group index or child task index
     *                                              navigates to the same task group/tasks in the context of its
     *                                              activity/task group in the 'show' view.
     * @param {boolean|undefined} hideSources       If true, skip the rendering of source elements.
     * @param {boolean|undefined} onlyShowAnswer    If true, do not render task content, only student answer view.
     * @param {number|undefined} singleAnswerUserId  If set , only show answer of a single student instead of group.
     *
     */
    initialize({
        work_on,
        isPreview,
        hasLinkToTask,
        hideSources,
        onlyShowAnswer,
        singleAnswerUserId,
        isExportingExamAnswers
    }) {

        _.bindAll(
            this,
            'onClickNavigateToTaskGroup',
            'showExplanation'
        );

        // If sources are hidden and there are no tasks, abort rendering this task group view.
        if (hideSources && this.model.tasks.length === 0) {
            return
        }

        const activityType = this.model.getActivityModel()?.get('type')

        // When navigating from author to "lesmateriaal-view" the pathname is: /task_groups/show
        const hasTextToSpeech = !hasLinkToTask &&
            activityType !== 'presentation' &&
            Util.stripTags(this.model.get('introduction')).length > 10 &&
            (window.location.pathname.startsWith('/activities/show') || window.location.pathname.startsWith('/task_groups/show'))

        this.setElement(Template({
            Styles,
            index: this.model.get('index'),
            introduction: Util.renderContentSafely(this.model.get('introduction')),
            isPresentation:
                // Check if user is actually inside a presentation and is not loading a
                // taskgroup belonging to a presentation. F.e. when checking students answers.
                activityType === 'presentation' &&
                /^Presentation/.test(work_on.el.classList.value),
            hasLinkToTask,
            hasTextToSpeech
        }));

        this.taskGroupElements = this.$('.js-task-group-elements');
        var taskGroupOptionalElements = this.$('.js-task-group-optional-elements');

        var taskViews = [];

        // Show button for explanation attached to this taskgroup
        // Only show it in supported activity types and when on activity/show page (and not in progress or library)
        const explanationLabel = this.model.getExplanationLabel(true)
        if (explanationLabel &&
            ['linear', 'diagnostic_exam', 'adaptive', 'adaptive_student'].includes(activityType) &&
            work_on.el.closest('.js-activity-show')
        ) {
            this.addChildView(
                new Button({
                    label: explanationLabel,
                    icon: 'learning-text',
                    inline: true,
                    callback: this.showExplanation
                }),
                '.js-explanation-button'
            )
        }

        // If no optional/additional elements are found, empty the optional materials element.
        const optionalElement = this.model.elements.findWhere({
            is_additional: true
        })
        if (!optionalElement || optionalElement.get('element_type') === 'source' && hideSources === true) {
            taskGroupOptionalElements.empty();
        }

        if (hasTextToSpeech) {
            this.addSvelteChildView(
                '.js-text-to-speech',
                TextToSpeech,
                {
                    contentUrl: 'task_groups',
                    contentId: this.model.id,
                    showCallback: () => {
                        this.$('.js-taskgroup-index-container').addClass(Styles.text_to_speech_active);
                    },
                    hideCallback: () => {
                        this.$('.js-taskgroup-index-container').removeClass(Styles.text_to_speech_active);
                    }
                }
            )
        } else if (hasLinkToTask) {
            this.$('.js-taskgroup-index-container').on(
                'click',
                this.onClickNavigateToTaskGroup
            )
        }

        // Create a sources collection using a deep clone of all the elements to prevent changing the input data.
        // TODO: in the future consider using https://developer.mozilla.org/en-US/docs/Web/API/structuredClone
        // for all instances where we need to do a deep clone of something. The JSON method is "lossy" since
        // it converts only JSON-compatible data types. Data types such as Date, Infinity, Map, Set, undefined, etc.
        // are converted to null. See discussion https://stackoverflow.com/a/122704
        const theoryCollection = new TheorySourcesCollection(
            JSON.parse(JSON.stringify(this.model.elements.toJSON())),
            {parse: true}
        )

        for (const elementModel of this.model.elements.models) {

            let elementView

            if (elementModel.get('element_type') === 'task') {

                elementView = new TaskView({
                    model: elementModel,
                    work_on,
                    onlyShowAnswer,
                    activityModel: this.model.getActivityModel(),
                    hasLinkToTask,
                    singleAnswerUserId,
                    isPreview,
                    isExportingExamAnswers,
                    canBeEdited: this.model.canBeEdited(),
                })
                taskViews.push(elementView)

            } else if (!hideSources && elementModel.get('element_type') === 'source') {

                const mergedSourceModel = theoryCollection.find((sourceModel) => {
                    return sourceModel.get('mergedSourceIds')[0] === elementModel.id
                })
                if (!mergedSourceModel) {
                    continue
                }

                elementView = new SourceView({
                    model: mergedSourceModel,
                    work_on,
                    activityModel: this.model.getActivityModel(),
                    isPreview,
                })

            }

            if (elementView) {
                this.registerChildView(elementView)
                if (elementModel.get('is_additional')) {
                    elementView.appendTo(taskGroupOptionalElements)
                } else {
                    elementView.appendTo(this.taskGroupElements)
                }
            }

        }

        if (activityType === 'presentation' && this.model.elements.length > 1) {
            this.taskGroupElements.addClass(Styles['has-multiple-slide-elements'])
        }

        this.taskViews = taskViews;

        // Show demo user the tooltip pointing to menu progress button
        if (Backbone.Model.user.get('is_demo')) {
            this.tourToolTip = Backbone.View.layout.addTourTooltip({
                message: window.i18n.gettext('Go to the progress overview'),
                target: '.js-default-menu [href="/groups/progress"]',
                targetIsFixed: true
            });
        }
    }

    /**
     * onDestroy
     * This function is triggered when the view is destroyed.
     * It will then close all open tool tips
     */
    onDestroy() {
        // fixes the duplication of toolTips while navigating through the taskgroups
        if (this.tourToolTip) {
            Backbone.View.layout.unregisterAndDestroyChildView(this.tourToolTip);
        }

        AudioUtil.stopAllAudio()
    }

    /**
     * Navigate to task group at activities/show.
     */
    onClickNavigateToTaskGroup() {
        Backbone.history.navigate(
            '/activities/show/' + this.model.get('parent_id') + '/' + this.model.get('id'), {
                trigger: true
            }
        );
    }

    // Open explanation sidebar using the context of the current task group. Open in
    // side bar overlay if smaller than 1200, or next to content if => 1200px
    showExplanation() {

        if (Backbone.View.sidebar.view instanceof SidebarLearningText) {
            Backbone.View.sidebar.closeSidebar()
            return
        }

        // otherwise show the learning text in the sidebar
        var sidebarLearningTextView = new SidebarLearningText({
            collection: this.model.learningTexts,
            activityModel: this.model.getActivityModel(),
        });
        Backbone.View.sidebar.showSidebar(
            sidebarLearningTextView,
            this.model.getExplanationLabel(),
            Math.max((window.innerWidth - 100) / 2, 600),
            null,
            window.innerWidth > 1199
        );
        this.registerChildView(sidebarLearningTextView);

    }

}
