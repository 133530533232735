<script>
    import Icon from "../icon/Icon.svelte";
    import Spinner from "../spinner/Spinner.svelte";
    import TextToSpeechUtil from "util/TextToSpeechUtil";
    import { addActiveAudio, removeActiveAudio } from 'stores/audioStore';
    import AudioUtil from 'util/AudioUtil';

    export let contentUrl
    export let contentId
    export let showCallback = () => {}
    export let hideCallback = () => {}
    export let maxAttempts = 16

    let isLoading = false
    let isPlayingAudio = false
    let audio = new Audio()

    export function toggleTextToSpeech() {
        if (isLoading) {
            return
        }

        if (isPlayingAudio) {
            audio.pause()
            isPlayingAudio = false
            audio = new Audio()
            return
        }

        startAudio()
    }


    function startAudio(attempt = 0) {
        isLoading = true;
        showCallback()

        attempt++

        let fetchPromise = TextToSpeechUtil.fetchAudio(
            `/${contentUrl}/synthesize/${contentId}.json`, true
        ).then((blob) => {
            Backbone.View.layout.closeStatus()

            audio.src = URL.createObjectURL(blob)
            audio.play()

            isLoading = false

            addActiveAudio(audio)

            audio.addEventListener('play', () => {
                AudioUtil.stopAllAudio(audio)
                isPlayingAudio = true
            })

            audio.addEventListener('pause', () => {
                isPlayingAudio = false
                removeActiveAudio(audio)
                hideCallback()
            })
            audio.addEventListener('ended', () => {
                isPlayingAudio = false
                removeActiveAudio(audio)
                hideCallback()
            })
        })

        fetchPromise.catch((error) => {
            if (error.error_code === 35202) {
                if (attempt > maxAttempts) {
                    isLoading = false
                    Backbone.View.layout.openStatus(
                        window.i18n.gettext('The audio could not be downloaded. Try again later.')
                    )
                } else {
                    setTimeout(() => {
                        startAudio(attempt)
                    }, 500)
                    if (attempt === 4) {
                        Backbone.View.layout.openStatus(error.displayMessage, 'loading', {noHide: true})
                    }
                }
            } else {
                if (error?.displayMessage) {
                    Backbone.View.layout.openStatus(error.displayMessage);
                } else {
                    Backbone.View.layout.closeStatus()
                }

                isLoading = false;
                hideCallback();
            }
        })
    }
</script>

<button class="status-icon" on:click={() => toggleTextToSpeech()}>
    <div class="icon-holder">
        {#if isLoading}
            <div class="spinner">
                <Spinner isInsideButton={true}></Spinner>
            </div>
        {:else}
            {#if isPlayingAudio}
                <Icon name={'stop'}></Icon>
            {:else}
                <Icon name={'volume-up'}></Icon>
            {/if}
        {/if}
    </div>
</button>

<style lang="scss">
    .status-icon {
        display: flex;
        position: absolute;
        inset: 0;
        cursor: pointer;
        border-radius: 8px;

        .icon-holder {
            margin: auto;
        }

        :global(svg) {
            width: 16px;
            height: 16px;
            display: flex;
        }
    }
</style>
